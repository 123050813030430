<template>
  <v-container>
    <div class="base-container">
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        sort-by="locationName"
        disable-pagination
        hide-default-footer
        fixed-header
        height=500
        class="elevation-1"
      >
        <!-- :items-per-page="5" -->
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Machine Master</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-btn
              color="info"
              dark
              class="mb-2"
              @click="refresh"
            >
              Refresh
            </v-btn>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-dialog
              v-model="dialog"
              max-width="650px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Machine
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <!--
                          Condition for autofocus
                          ref: https://stackoverflow.com/questions/51472947/vuetifys-autofocus-works-only-on-first-modal-open
                        -->
                        <v-text-field
                          v-if="dialog"
                          autofocus
                          :readonly="editedIndex >= 0"
                          v-model="editedItem.serialNumber"
                          :rules="requiredRules"
                          label="Serial Number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.ipAddress"
                          :rules="ipAddressRules"
                          label="IP Address"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-switch
                          v-model="editedItem.isCartonPackingMachine"
                          inset
                          color="success"
                          :label="`Carton Packing Machine:
                            ${editedItem.isCartonPackingMachine ? 'Yes' : 'No'}`"
                        ></v-switch>
                          <!-- :label="`Switch 1: ${switch1.toString()}`" -->
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-switch
                          v-model="editedItem.isActive"
                          inset
                          color="success"
                          :label="`Active: ${editedItem.isActive ? 'Yes' : 'No'}`"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-combobox
                          v-model="editedItem.location"
                          :items="locationMasterItems"
                          item-text="locationName"
                          item-value="locationId"
                          label="Location"
                          outlined
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.fileName="{ item }">
          <a href="#" @click="viewCertificate(item)">{{ item.fileName }}</a>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <!-- <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
        </template>
        <template v-slot:no-data>
          <!-- <v-btn
            color="primary"
            @click="initialize"
          >
            Reset
          </v-btn> -->
        </template>
      </v-data-table>
    </div>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
// import productApi from '@/api/product';
import machineApi from '@/api/machine';

// const moment = require('moment');
const _ = require('lodash');

export default {
  data: () => ({
    snackbar: false,
    snackbarText: null,
    search: null,
    alertType: 'success',
    alertMsg: 'Genuine Product',
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Location', align: 'start', value: 'locationName' },
      { text: 'Serial No.', align: 'start', value: 'serialNumber' },
      { text: 'IP Address', value: 'ipAddress' },
      { text: 'Carton Machine?', value: 'isCartonPackingMachine' },
      { text: 'Active?', value: 'isActive' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    items: [],
    locationMasterItems: [
      { locationId: 1, locationName: 'Taloja T-15' },
      { locationId: 2, locationName: 'Taloja L-76' },
      { locationId: 3, locationName: 'Taloja L-17' },
      { locationId: 4, locationName: 'Pawne' },
      { locationId: 5, locationName: 'Rabale' },
    ],
    requiredRules: [
      (v) => v !== '' || 'Input is required',
    ],
    ipAddressRules: [
      (v) => v !== '' || 'Input is required',
      (v) => /^\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}$/.test(v) || 'Must be a valid IP address',
    ],

    defaultItem: {
      location: null,
      serialNumber: null,
      ipAddress: null,
      isCartonPackingMachine: false,
      isActive: false,
    },
    editedItem: {
      location: null,
      serialNumber: null,
      ipAddress: null,
      isCartonPackingMachine: false,
      isActive: false,
    },
    editedIndex: -1,
  }),

  mounted() {
    console.log('mounted')
  },

  created() {
    // console.log(this.$route.params.s)
    // this.fetchCertificate(this.$route.query.s)
    // const s = this.$route.query.s || this.$route.params.s;

    // for (let index = 0; index < this.numberOfItems; index += 1) {
    //   this.editedItem.push({ ...this.defaultItem })
    // }
    this.fetchCertificate();
  },

  watch: {
    dialog(val) {
      return val || this.close()
    },
    dialogDelete(val) {
      console.log(val)
      // val || this.closeDelete()
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Machine' : 'Edit Machine'
    },
  },

  methods: {
    close() {
      // this.dialog = false
      this.$nextTick(() => {
        console.log('close')
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = { ...item }
      this.editedItem.location = _.find(
        this.locationMasterItems,
        { locationName: item.locationName },
      )
      console.log(this.editedItem)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const item = this.items.splice(this.editedIndex, 1)
      if (item.length > 0) {
        machineApi.delete(item.serialNumber)
      }

      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = null
        this.editedIndex = -1
      })
    },

    save() {
      // if (this.editedIndex > -1) {
      //   Object.assign(this.desserts[this.editedIndex], this.editedItem)
      // } else {
      //   this.desserts.push(this.editedItem)
      // }
      // this.editedItem.forEach((e) => this.desserts.push(e))
      console.log(this.editedItem)
      machineApi
        .save(this.editedItem.serialNumber,
          this.editedItem.ipAddress,
          this.editedItem.isCartonPackingMachine,
          this.editedItem.isActive,
          this.editedItem.location.locationId)
        .then((res) => {
          console.log(res)
          this.refresh()
        })

      this.dialog = false
    },

    refresh() {
      this.fetchCertificate();
    },

    fetchCertificate() {
      this.items = [];

      machineApi
        .fetch()
        .then((response) => {
          const res2 = response.data;
          console.log(res2);
          if (res2.success) {
            const { data } = res2;

            _.each(data, (item) => {
              this.items.push({
                serialNumber: item.serialNumber,
                locationName: item.locationName,
                ipAddress: item.ipAddress,
                isCartonPackingMachine: item.isCartonPackingMachine,
                isActive: item.isActive,
              })
            })
          }
        })
        .catch((err) => {
          console.log(err);
          this.snackbarText = err;
          this.snackbar = true;
        });
    },
    // viewCertificate(item) {
    //   if (item.batchNumber) {
    //     productApi.viewTestCertificate(item.batchNumber)
    //   } else {
    //     this.snackbarText = 'Unable to find test certificate file.';
    //     this.snackbar = true;
    //   }
    // },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
